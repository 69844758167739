<template>
    <div>
        <div class="banner-section">
            <div class="container">
                <div class="row m-0 align-items-center">
                    <div class="col-lg-5 col-md-12 p-0">
                        <div class="hero-content">
                            <h1>Welcome to Your Digital Exchange</h1>
                            <p>Swipe/exchange your home currency while resting on your couch</p>

                            <div class="single-information-box mt-4">

                                <div class="btn-box">
                                    <a href="#" class="app-store-btn" @mouseenter="show()">
                                        <i class="flaticon-apple"></i>
                                        Download on
                                        <span>App Store</span>
                                    </a>

                                    <a href="#" class="play-store-btn" @mouseenter="show()">
                                        <i class="flaticon-play-store"></i>
                                        Download on
                                        <span>Google play</span>
                                    </a>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12 p-0">
                        <div class="hero-image">

                            <div class="main-image">
                                <img src="../../assets/img/main-payment.png" alt="image">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="wow bounceIn text-white animated absolute" :style="'display:' + showOrHide" data-wow-delay="0.9s">
            <button @click="hide()" class="close"><i class="fa fa-times"></i></button>

            <ComingSoon />

        </div>

    </div>
</template>

<script>
import ComingSoon from "../coming-soon/ComingSoon";

export default {
    name: 'MainBanner',
    data() {
        return {
            isPopup: false,
            isCounterVisible: 'none'
        }
    },
    components: {
        ComingSoon
    },
    methods: {
        isPopupMethod(isPopup) {
            return this.isPopup = !isPopup
        },
        hide() {
            this.isCounterVisible = 'none';
        },
        show() {
            this.isCounterVisible = 'block';
        },
    },
    computed: {
        showOrHide() {
            return this.isCounterVisible;
        }
    },
}
</script>