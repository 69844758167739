<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <PrivacyPolicyArea :parivacy-policy="companyInfo" />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../privacy-policy/PageTitle'
import PrivacyPolicyArea from '../privacy-policy/PrivacyPolicyArea'
import FooterWidget from '../layout/Footer'
import { companyInfo } from '@/assets/scripts/app'

export default {
    name: 'PrivacyPolicy',
    data() {
        return {
            companyInfo: companyInfo
        }
    },
    components: {
        NavbarWidget,
        PageTitle,
        PrivacyPolicyArea,
        FooterWidget,
    }
}
</script> 