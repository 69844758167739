<template>
    <div class="team-area ptb-70 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Meet Our Team</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6" v-for="team, key in teams" :key="key">

                    <div class="single-team-member">
                        <div class="member-image">
                            <div class="image">
                                <img v-bind:src="team.photoUrl" v-bind:alt="team.name">
                            </div>

                            <ul class="social">
                                <li><a v-bind:href="team.fbUrl" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li><a v-bind:href="team.linkedinUrl" target="_blank"><i
                                            class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>

                        <div class="member-content">
                            <h3>{{ team.name }}</h3>
                            <span>{{ team.rank }}</span>
                            <p>{{ team.note }}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from "@/assets/scripts/firebaseInit";

export default {
    name: 'TeamPage',
    data() {
        return {
            teams: [],
        };
    },
    methods: {
        async fetchTeams() {
            const docs = await db.getDocs(db.collection(db.firestore, 'Company/Info/Teams'));
            docs.forEach((doc) => {
                this.teams.push(doc.data());
            });
        }
    },
    mounted() {
        this.fetchTeams();
    },
}
</script>