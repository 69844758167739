<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">

                <div class="overview-content">
                    <div class="content left-content">
                        <h2>Affordable Exchange Rate</h2>
                        <div class="bar"></div>
                        <p>We maintain peoples' trust by always giving them real and low exchange rates with zero hidden
                            fees.</p>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/img/affordable.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesThree'
}
</script>