<template>
    <div class="blog-details-area ptb-70">
        <div class="container">
            <div style="text-align: center;" v-if="isLoading">
                <LoadingWidget loading-text="Loading" />
            </div>
            <div class="row" v-else>
                <div class="col-lg-8 col-md-12" v-if="post != null">
                    <div class="blog-details">
                        <div class="article-content">
                            <div class="entry-meta">
                                <ul>
                                    <li><span>Posted On:</span>{{ formatDate(post.dateCreated.toDate()) }}</li>
                                    <li><span>Posted By:</span>
                                        <router-link to="/about-us">
                                            {{ post.author }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>

                            <h3 class="mb-4">{{ post.title }}</h3>

                            <div class="article-image mb-4">
                                <img :src="post.postImage" alt="Post Image">
                            </div>

                            <span v-html="processPost(post.post)"></span>

                            <!-- <ul class="wp-block-gallery columns-3">
                                <li class="blocks-gallery-item">
                                    <figure>
                                        <img src="../../assets/img/blog-1.jpg" alt="image">
                                    </figure>
                                </li>
                            </ul> -->

                        </div>

                        <div class="article-footer">
                            <div class="article-tags">
                                <span><i class="fas fa-share"></i></span>
                                Share
                            </div>

                            <div class="article-share">
                                <ul class="social">
                                    <li><a href="https://www.facebook.com/" target="_blank"><i
                                                class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.twitter.com/" target="_blank"><i
                                                class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/" target="_blank"><i
                                                class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://www.instagram.com/" target="_blank"><i
                                                class="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="../../assets/img/author1.jpg" class="avatar" alt="image">
                                            <b class="fn">James Anderson</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <time>April 24, 2019 at 10:59 am</time>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type specimen book.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="../../assets/img/author2.jpg" class="avatar"
                                                        alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <time>April 24, 2019 at 10:59 am</time>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen book.</p>
                                            </div>

                                            <div class="reply">
                                                <a href="#" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>

                                    <ol class="children">
                                        <li class="comment">
                                            <article class="comment-body">
                                                <footer class="comment-meta">
                                                    <div class="comment-author vcard">
                                                        <img src="../../assets/img/author3.jpg" class="avatar"
                                                            alt="image">
                                                        <b class="fn">Sarah Taylor</b>
                                                        <span class="says">says:</span>
                                                    </div>

                                                    <div class="comment-metadata">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </div>
                                                </footer>

                                                <div class="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever
                                                        since the 1500s, when an unknown printer took a galley of type
                                                        and scrambled it to make a type specimen book.</p>
                                                </div>

                                                <div class="reply">
                                                    <a href="#" class="comment-reply-link">Reply</a>
                                                </div>
                                            </article>
                                        </li>
                                    </ol>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="../../assets/img/author2.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <time>April 24, 2019 at 10:59 am</time>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type specimen book.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="../../assets/img/author4.jpg" class="avatar"
                                                        alt="image">
                                                    <b class="fn">James Anderson</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <time>April 24, 2019 at 10:59 am</time>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen book.</p>
                                            </div>

                                            <div class="reply">
                                                <a href="#" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525"
                                        required="required"></textarea>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url">
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent"
                                        id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this
                                        browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post Comment">
                                </p>
                            </form>
                        </div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import LoadingWidget from "../common/LoadingWidget";
import { formatDate } from '@/assets/scripts/app';
import { processPost } from '@/assets/scripts/app';
import { db } from "@/assets/scripts/firebaseInit";
import { query, where } from "firebase/firestore";

export default {
    name: 'BlogDetails',
    components: {
        LoadingWidget,
    },
    data() {
        return {
            formatDate: formatDate,
            processPost: processPost,
            post: null,
            isLoading: true,
            title: this.$route.query.post.replaceAll('-', ' '),
        }
    },
    mounted() {
        (async () => {
            const q = query(db.collection(db.firestore, 'Company/Info/Blog'), where('title', '==', this.title));
            const querySnapshot = await db.getDocs(q);
            const doc = querySnapshot.docs[0];
            if (!doc) {
                window.location.href = '/error';
            } else {
                this.post = doc.data();
                this.isLoading = false;
            }
        })()
    },
}
</script>