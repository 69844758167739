<template>
    <div class="page-title-area item-bg4">
        <div class="container">
            <div class="ctp-page-banner-content">
                <h3>Terms & Condition</h3>
                <ul class="list">
                    <li>
                        <router-link to="/home">Home</router-link>
                    </li>
                    <li>AbSwipe Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>