<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>AbSwipe Privacy Policy</h2>
                <div class="bar"></div>
                <p>This policy was last updated November 25, 2022.</p>
            </div>
            <div class="privacy-policy-content">
                <span v-if="parivacyPolicy" v-html="processPost(parivacyPolicy.privacy)"></span>
            </div>

        </div>
    </div>
</template>

<script>
import { processPost } from '@/assets/scripts/app';

export default {
    name: 'PrivacyPolicyArea',
    methods: {
        processPost: processPost
    },
    props: {
        parivacyPolicy: Object
    }
}
</script>