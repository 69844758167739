<template>
    <div class="preloader">
        <div class="loader">
            <div class="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="shadow"></div>
            <div class="box"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreloaderWidget'
}
</script>
