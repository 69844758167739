<template>
    <div class="funfacts-area ptb-70 pt-0">
        <div class="container">
            <div class="section-title">
                <h2>We always try to understand customers expectation</h2>
                <div class="bar"></div>
                <p>The digital experience is made possible with cheaper and more available technology.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3>1000+</h3>
                        <p>Downloaded</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3>500+</h3>
                        <p>Feedback</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3>20+</h3>
                        <p>Workers</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3>10+</h3>
                        <p>Contrubutors</p>
                    </div>
                </div>
            </div>

            <div class="map-bg">
                <img src="../../assets/img/map.png" alt="map">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FunFacts'
}
</script>