<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <ContactArea />
        <AppDownload />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../contact/PageTitle'
import ContactArea from '../contact/ContactArea'
import AppDownload from '../common/AppDownload.vue'
import FooterWidget from '../layout/Footer'
// import { db } from '@/assets/scripts/firebaseInit';
// import { useDocument } from 'vuefire'

export default {
    name: 'ContactPage',
    data() {
        return {

        }
    },
    components: {
        NavbarWidget,
        PageTitle,
        ContactArea,
        AppDownload,
        FooterWidget,
    },
}
</script>