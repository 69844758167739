<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <BlogDetails />
        <AccountCreate />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../blog-details/PageTitle'
import BlogDetails from '../blog-details/BlogDetails'
import AccountCreate from '../common/AccountCreate'
import FooterWidget from '../layout/Footer'

export default {
    name: 'BlogThree',
    components: {
        NavbarWidget,
        PageTitle,
        BlogDetails,
        AccountCreate,
        FooterWidget,
    }
}
</script>