<template>
    <div class="page-title-area item-bg3">
        <div class="container">
            <div class="ctp-page-banner-content">
                <h3>Privacy Policy</h3>
                <ul class="list">
                    <li>
                        <router-link to="/home">Home</router-link>
                    </li>
                    <li>AbSwipe privacy policy</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>