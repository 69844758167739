<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <FaqArea />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../faq/PageTitle'
import FaqArea from '../faq/FaqArea'
import FooterWidget from '../layout/Footer'

export default {
    name: 'FaqPage',
    components: {
        NavbarWidget,
        PageTitle,
        FaqArea,
        FooterWidget,
    }
}
</script>