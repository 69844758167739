import { createWebHistory, createRouter } from "vue-router";

import HomePage from "../components/pages/home";
import AboutUsPage from "../components/pages/AboutUsPage";
import HowItWorksPage from "../components/pages/HowItWorksPage";
import LoginPage from "../components/pages/Login";
import ErrorPage from "../components/pages/Error";
import FaqPage from "../components/pages/Faq";
import BlogOne from "../components/pages/BlogOne";
import BlogThree from "../components/pages/BlogThree";
import ContactPage from "../components/pages/Contact";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import TermsCondition from "../components/pages/TermsCondition";

const routes = [
  { path: "/", component: HomePage },
  { path: "/home", component: HomePage },
  { path: "/about-us", component: AboutUsPage },
  { path: "/how-it-works", component: HowItWorksPage, /* meta: { requiresAuth: true } */ },
  { path: "/dashboard/login", component: LoginPage },
  { path: "/error", component: ErrorPage },
  { path: "/faq", component: FaqPage },
  { path: "/blog", component: BlogOne },
  { path: "/blog-details", component: BlogThree },
  { path: "/contact", component: ContactPage },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-condition", component: TermsCondition },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
