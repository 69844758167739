<template>
    <div>
        <NavbarStyleTwo />
        <MainBanner />
        <ServicesWidget />
        <ServicesTwo />
        <ServicesThree />
        <ServicesFour />
        <FunFacts />
        <Feedback />
        <AppDownload />
        <AccountCreate />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarStyleTwo from '../layout/NavbarStyleTwo'
import MainBanner from '../home/MainBanner'
import ServicesWidget from '../common/Services'
import ServicesTwo from '../common/ServicesTwo'
import ServicesThree from '../common/ServicesThree'
import ServicesFour from '../common/ServicesFour'
import FunFacts from '../common/FunFacts'
import Feedback from '../common/Feedback'
import AppDownload from '../common/AppDownload'
import AccountCreate from '../common/AccountCreate'
import FooterWidget from '../layout/Footer'


export default {
    name: 'HomePage',
    components: {
        NavbarStyleTwo,
        MainBanner,
        ServicesWidget,
        ServicesTwo,
        ServicesThree,
        ServicesFour,
        FunFacts,
        Feedback,

        AppDownload,
        AccountCreate,
        FooterWidget,
    },
    data() {
        return {
            isCounterVisible: 'block'
        }
    },
    computed: {
        showOrHide() {
            return this.isCounterVisible;
        }
    },
    methods: {
        hide() {
            this.isCounterVisible = 'none';
        }
    },
}
</script>
