<template>
    <div class="ctp-money-transfer-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title mb-5">
                <h2>How Does AbSwipe Work?</h2>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Create an Account</h3>
                        <div class="image">
                            <img src="../../assets/img/new-user.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Download AbSwipe from Google Play or App Store and create a new account.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Complete KYC</h3>
                        <div class="image">
                            <img src="../../assets/img/kyc.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Provide any of the available valid identity to verify your account.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Add Your Foreign Bank Account</h3>
                        <div class="image">
                            <img src="../../assets/img/add-bank.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Add atleast one active foreign bank account which will be used to receive your funds. (small
                            amount will deposited to verify the account is active)</p>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Initiate a Swipe</h3>
                        <div class="image">
                            <img src="../../assets/img/init-trans.png" alt="image">
                            <div class="number">04</div>
                        </div>
                        <p>Enter the amount to swipe from a domestic card (overview of the amount to be received will be
                            display base on the current rate).</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Select or Provide Card</h3>
                        <div class="image">
                            <img src="../../assets/img/choose-card.png" alt="image">
                            <div class="number">05</div>
                        </div>
                        <p>Select/provide the card to swipe from and proceed.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Payment Processor</h3>
                        <div class="image">
                            <img src="../../assets/img/processor.png" alt="image">
                            <div class="number">06</div>
                        </div>
                        <p>Payment processor process and take you to your bank page for verification (OTP) and deduct
                            the given amount.</p>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Amount Got Settled instantly</h3>
                        <div class="image">
                            <img src="../../assets/img/amount-settled.png" alt="image">
                            <div class="number">07</div>
                        </div>
                        <p>Our system verify and confirm the order and instantly transfer the amount into your chosen
                            foreign bank account.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Finally!</h3>
                        <div class="image">
                            <img src="../../assets/img/withdraw.png" alt="image">
                            <div class="number">08</div>
                        </div>
                        <p>Congratulation! now you finally have your money in your foreign bank account, use any nearest
                            ATM to withdraw your foreign currency money and enjoy.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'MoneyTransfer'
}
</script>