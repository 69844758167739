<template>
    <div>
        <footer class="footer-area" v-if="companyInfo != null">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <div class="logo">
                                <router-link to="/"><img src="../../assets/img/h/lockup_abswipe_horizontal.png"
                                        width="160" alt="logo"></router-link>
                                <p>{{ companyInfo.shortDesc }}</p>
                            </div>

                            <ul class="social-links">
                                <li><a href="https://www.facebook.com/abswipe" target="_blank"><i
                                            class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.twitter.com/abswipe" target="_blank"><i
                                            class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/abswipe" target="_blank"><i
                                            class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/abswipe" target="_blank"><i
                                            class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget pl-5">
                            <h3>Company</h3>

                            <ul class="list">
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <li><router-link to="/contact">Contact Us</router-link></li>
                                <li><router-link to="/how-it-works">How It Works</router-link></li>
                                <li><router-link to="/blog">Latest News</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <h3>Support</h3>

                            <ul class="list">
                                <li><router-link to="/faq">FAQ's</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li><router-link to="/terms-condition">Terms & Condition</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <h3>Address</h3>

                            <ul class="footer-contact-info">
                                <li><span>Location:</span>{{ companyInfo.officeAddress }}</li>
                                <li v-for="email, index in companyInfo.supportMails" :key="index"><span>Email:</span> <a
                                        :href="'mailto:' + email">{{ email }}</a>
                                </li>
                                <li v-for="contact, index in companyInfo.contacts" :key="index"><span>Phone:</span> <a
                                        :href="'tel:' + contact">{{ formatNumber(contact) }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="copyright-area">
                    <p>&copy; {{ currentYear }} AbSwipe. All rights reserved</p>
                </div>
            </div>

            <div class="map-image"><img src="../../assets/img/map.png" alt="map"></div>
        </footer>
        <!-- End Footer Area -->

        <div @click="scrollToTop()" :class="['back-to-top-btn', { 'go-top': isTop }]">
            <i class="fas fa-arrow-up"></i>
        </div>
    </div>
</template>

<script>
import { db } from '@/assets/scripts/firebaseInit';
import { useDocument } from 'vuefire'

export default {
    name: 'FooterWidget',
    data() {
        return {
            isTop: false,
            currentYear: new Date().getFullYear(),
            companyInfo: null,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        formatNumber(number) {
            const insert = (arr, index, ...newItems) => [
                ...arr.slice(0, index),
                ...newItems,
                ...arr.slice(index)
            ]
            var formated = insert(String(number).split(''), 3, ' (');
            formated = insert(formated, 7, ') ');
            formated = insert(formated, 11, ' ');
            return formated.toString().replaceAll(',', '');
        }
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isTop = true
            } else {
                that.isTop = false
            }
        }),
            (() => {
                this.companyInfo = useDocument(db.doc(db.firestore, 'Company/Info'));
            })()
    }
}
</script>