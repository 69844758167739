<template>
    <div class="page-title-area item-bg2">
        <div class="container">
            <div class="ctp-page-banner-content">
                <h3>{{ title }}</h3>
                <ul class="list">
                    <li>
                        <router-link to="/home">Home</router-link>
                    </li>
                    <li><router-link to="/blog">Blog</router-link></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    data() {
        return {
            title: this.$route.query.post.replaceAll('-', ' '),
        }
    },

}
</script>