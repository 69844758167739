<template>
    <div class="ptb-70">
        <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div>
        <h3 class="mt-4">{{ loadingText }}</h3>
    </div>
</template>
<script>
export default {
    name: 'LoadingWidget',
    props: {
        loadingText: String
    }
}
</script>
