<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>AbSwipe Terms of Service</h2>
                <div class="bar"></div>
                <p>INFORMATION & NOTICES</p>
            </div>


            <div class="privacy-policy-content">
                <span v-if="termsConditions" v-html="processPost(termsConditions.terms)"></span>
            </div>


        </div>
    </div>
</template>

<script>
import { processPost } from '@/assets/scripts/app';
export default {
    name: 'TermsConditionArea',
    methods: {
        processPost: processPost
    },
    props: {
        termsConditions: Object
    }
}
</script>