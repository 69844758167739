<template>
    <div class="blog-area ptb-70">
        <div class="container">
            <div style="text-align: center;" v-if="isLoading">
                <LoadingWidget loading-text="Loading" />
            </div>
            <div v-else-if="blogPosts == null || blogPosts.length == 0">

                <NoRecordWidget message="No blog has beeen posted yet." />

            </div>
            <div v-else class="row">
                <div class="col-lg-4 col-md-6" v-for="post, index in blogPosts" :key="index">
                    <div class="single-blog-post">
                        <div class="blog-image">
                            <router-link to="/blog-details">
                                <img :src="post.postImage" alt="image">
                            </router-link>

                            <div class="date">
                                <i class="far fa-calendar-alt"></i> {{ formatDate(post.dateCreated.toDate()) }}
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <h3>
                                <router-link to="/blog-details">
                                    {{ post.title }}
                                </router-link>
                            </h3>
                            <span>By <a href="#">{{ post.author }}</a></span>

                            <p>
                                {{ getShort(post.post) }}
                            </p>

                            <router-link :to="'/blog-details?post=' + post.title.replaceAll(' ', '-')"
                                class="read-more-btn">
                                Read More <i class="fas fa-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="/blog-1" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                        <a href="/blog-1" class="page-numbers">1</a>
                        <span class="page-numbers current" aria-current="page">2</span>
                        <a href="/blog-1" class="page-numbers">3</a>
                        <a href="/blog-1" class="page-numbers">4</a>
                        <a href="/blog-1" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import LoadingWidget from "../common/LoadingWidget";
import NoRecordWidget from "../common/NoRecordWidget";
import { db } from '@/assets/scripts/firebaseInit'
import { useCollection } from "vuefire";
import { formatDate } from '@/assets/scripts/app'
import { processPost } from '@/assets/scripts/app'

export default {
    name: 'BlogWidget',
    data() {
        return {
            blogPosts: null,
            isLoading: true,
        }
    },
    components: {
        LoadingWidget,
        NoRecordWidget
    },
    methods: {
        formatDate: formatDate,
        processPost: processPost,
        getShort: (post) => {
            const passedPost = String(post);
            var firstParap = passedPost.substring(3, passedPost.indexOf('[/p]'));
            return firstParap + '..';
        }
    },
    mounted() {
        (() => {
            const blog = useCollection(db.collection(db.firestore, 'Company/Info/Blog'));
            this.blogPosts = blog;
            this.isLoading = false;
        })()
    },
}
</script>