<template>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
        <div class="abswipe-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/img/h/lockup_abswipe_horizontal_wht.png" width="140" alt="image">
                        <img src="../../assets/img/h/lockup_abswipe_horizontal.png" width="140" alt="image">
                    </router-link>

                    <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state">
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <b-collapse class="collapse navbar-collapse" :class="{ show: active }" is-nav>
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <router-link to="/home" class="nav-link">
                                    Home
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/about-us" class="nav-link">
                                    About Us
                                </router-link>
                            </li>
                            <li class="nav-item"><router-link to="/how-it-works" class="nav-link">How It
                                    Works</router-link>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    Company <i class="fas fa-chevron-down"></i>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/faq" class="nav-link">
                                            FAQ
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/terms-condition" class="nav-link">
                                            Terms & Conditions
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/privacy-policy" class="nav-link">
                                            Privacy Policy
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link to="/blog" class="nav-link">
                                    Blog
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">
                                    Contact
                                </router-link>
                            </li>
                        </ul>
                    </b-collapse>

                    <div class="others-options">

                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavbarWidget',
    data() {
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },

    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    }
}
</script>