import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, doc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDWh7BvJaoEoEw1-E9t0aJB3cQC2W5RLhQ",
    authDomain: "abswipe-app.firebaseapp.com",
    projectId: "abswipe-app",
    storageBucket: "abswipe-app.appspot.com",
    messagingSenderId: "166458921500",
    appId: "1:166458921500:web:5f5d3839e874cc331605d9"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const db = {
    firestore,
    collection,
    query,
    doc,
    getDoc,
    getDocs,
    setDoc,
    addDoc
}
export {
    firebaseApp,
    db,
}