<template>
    <div>
        <NavbarStyleTwo />
        <PageTitle />
        <div class="currency-transfer-provider-with-background-color">
            <MoneyTransfer />
            <SendMoneyOnline />
            <AppDownload />
        </div>
        <FooterWidget />
    </div>
</template>

<script>
import NavbarStyleTwo from '../layout/NavbarStyleTwo.vue'
import PageTitle from '../how-it-works/PageTitle'
import MoneyTransfer from '../how-it-works/MoneyTransfer'
import AppDownload from '../common/AppDownload.vue'
import SendMoneyOnline from '../how-it-works/SendMoneyOnline'
import FooterWidget from '../layout/Footer.vue'

export default {
    name: 'HowItWorksPage',
    components: {
        NavbarStyleTwo,
        PageTitle,
        MoneyTransfer,
        AppDownload,
        SendMoneyOnline,
        FooterWidget,
    }
}
</script>
