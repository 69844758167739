<template>
    <div class="contact-area ptb-70" v-if="companyInfo != null">
        <div class="container">
            <div class="section-title">
                <h2>Drop us message for any query</h2>
                <div class="bar"></div>
                <p>We are always ready to serve you.</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="contact-info">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <span>Address</span>
                                {{ companyInfo.officeAddress }}
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <span>Email</span>
                                <a v-for="email, index in companyInfo.supportMails" :key="index"
                                    :href="'mailto:' + email">{{ email }}</a>
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="fas fa-phone-volume"></i>
                                </div>
                                <span>Phone</span>
                                <a v-for="contact, index in companyInfo.contacts" :key="index"
                                    :href="'tel:' + contact">{{ formatNumber(contact) }}</a>
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <form id="contactForm">
                            <div v-if="isLoading" class="absolute">
                                <div>
                                    <div class="lds-facebook">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <h4 class="mt-4">Sending...</h4>
                                </div>
                            </div>
                            <div v-if="message != ''" class="absolute">
                                <div>
                                    <div style="width: 80%;">
                                        <i v-if="message.includes('success')" class="fa fa-check-circle"
                                            style="font-size: 60px;color:green;"></i>
                                        <i v-else class="fa fa-exclamation-circle"
                                            style="font-size: 60px;color:red;"></i>
                                    </div>
                                    <div style="width: 80%;">
                                        <h4 class="mt-5">{{ message }}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required
                                            data-error="Please enter your name" placeholder="Name">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required
                                            data-error="Please enter your email" placeholder="Email">
                                        <div class="help-block email-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required
                                            data-error="Please enter your number" class="form-control"
                                            placeholder="Phone">
                                        <div class="help-block number-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                            required data-error="Please enter your subject" placeholder="Subject">
                                        <div class="help-block subject-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6"
                                            required data-error="Write your message"
                                            placeholder="Your Message"></textarea>
                                        <div class="help-block body-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" @click="submitContact()" class="btn btn-primary">
                                        <span>Send Message</span>
                                    </button>
                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                    <div class="clearfix"></div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-map"><img src="../../assets/img/bg-map.png" alt="image"></div>
    </div>
</template>

<script>
import { companyInfo } from "@/assets/scripts/app.js";
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
    name: 'ContactArea',
    data() {
        return {
            companyInfo: null,
            isLoading: false,
            message: ''
        }
    },
    components: {
    },
    methods: {
        formatNumber(number) {
            const insert = (arr, index, ...newItems) => [
                ...arr.slice(0, index),
                ...newItems,
                ...arr.slice(index)
            ]
            var formated = insert(String(number).split(''), 3, ' (');
            formated = insert(formated, 7, ') ');
            formated = insert(formated, 11, ' ');
            return formated.toString().replaceAll(',', '');
        },
        async submitContact() {
            const form = document.getElementById('contactForm');
            form.addEventListener('submit', (e) => {
                const name = document.getElementById('name');
                const number = document.getElementById('phone_number');
                const email = document.getElementById('email');
                const subject = document.getElementById('msg_subject');
                const msg = document.getElementById('message');
                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                    const emailError = document.querySelector('.email-errors');
                    emailError.innerHTML = 'Invalid email entered';
                } else if (!/^\+(\d{2,3})\d{10,10}$/.test(number.value)) {
                    const numberError = document.querySelector('.number-errors');
                    numberError.innerHTML = 'Phone number should contain country code';
                } else if (!/(\w+[.,\n]?)+$/.test(msg.value)) {
                    const bodyError = document.querySelector('.body-errors');
                    bodyError.innerHTML = 'Invalid: message should only contain letter and numbers';
                } else {
                    this.isLoading = true;
                    const functions = getFunctions();
                    const sendContact = httpsCallable(functions, 'contactABS');
                    sendContact({
                        from: email.value,
                        subject: subject.value,
                        name: name.value,
                        number: number.value,
                        body: msg.value
                    })
                        .then(() => {
                            this.isLoading = false;
                            this.message = 'Thank, your message has been successefully received, we\'ll respond to you shortly';
                            setInterval(() => {
                                this.message = '';
                            }, 3000);
                        }).catch(() => {
                            this.isLoading = false;
                            this.message = 'Something went wrong while sending your meassage, please email us from your device mail.'
                            setInterval(() => {
                                this.message = '';
                            }, 3000);
                        });
                }
                form.reset();
                e.preventDefault();
            })
        }
    },
    mounted() {
        (() => {
            this.companyInfo = companyInfo;
        })()
    },
}
</script>
