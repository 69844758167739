<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/img/simply.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/img/circle.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="overview-content">
                    <div class="content">
                        <h2>Simply! Do It By Yourself</h2>
                        <div class="bar"></div>
                        <p>Get your home currency exchanged by us cheaply from the comfort of your home.</p>
                        <p></p>
                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Full data privacy</span></li>
                            <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesTwo'
}
</script>