import { db } from "./firebaseInit";
import { useDocument } from 'vuefire'

var companyInfo;

function initApp() {
    companyInfo = useDocument(db.doc(db.firestore, 'Company/Info'));
}
function formatDate(date) {
    var dt = new Date(date);
    var weekdays = new Array(7);
    weekdays[0] = "Sun";
    weekdays[1] = "Mon";
    weekdays[2] = "Tue";
    weekdays[3] = "Wed";
    weekdays[4] = "Thu";
    weekdays[5] = "Fri";
    weekdays[6] = "Sat";
    var days = dt.getDate();
    var day = weekdays[dt.getDay()].slice(0, 3);
    var year = dt.getFullYear();
    var month = (dt.getMonth() + 1);
    var hours = dt.getHours();
    var minutes = dt.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    days = days < 10 ? '0' + days : days;
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = day + ' ' + days + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function processPost(post) {
    var persedPost = String(post).replaceAll('[p]', '<p>');
    persedPost = persedPost.replaceAll('[/p]', '</p>')
        .replaceAll('[h]', '<h4>')
        .replaceAll('[/h]', '</h4>')
        .replaceAll('[img]', '<img src="')
        .replaceAll('[/img]', '" alt="Post-Image" class="blocks-gallery-item mb-4">')
        .replaceAll('[ul]', '<ul class="article-features-list">').replaceAll('[li]', '<li>').replaceAll('[/li]', '</li>')
        .replaceAll('[/ul]', '</ul>').replaceAll('[q]', '<blockquote class="wp-block-quote">').replaceAll('[/q]', '</blockquote>')
        .replaceAll('[cite]', '<cite>').replaceAll('[/cite]', '</cite>');
    return persedPost;
}
export {
    initApp,
    companyInfo,
    formatDate,
    processPost
}