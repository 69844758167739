<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <TermsConditionArea :terms-conditions="companyInfo" />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../terms-condition/PageTitle'
import TermsConditionArea from '../terms-condition/TermsConditionArea'
import FooterWidget from '../layout/Footer'
import { companyInfo } from '@/assets/scripts/app'
export default {
    name: 'TermsCondition',
    data() {
        return {
            companyInfo: companyInfo
        }
    },
    components: {
        NavbarWidget,
        PageTitle,
        TermsConditionArea,
        FooterWidget,
    }
}
</script> 