<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <BlogWidget />
        <AccountCreate />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../blog-one/PageTitle'
import BlogWidget from '../blog-one/Blog'
import AccountCreate from '../common/AccountCreate'
import FooterWidget from '../layout/Footer'

export default {
    name: 'BlogOne',
    components: {
        NavbarWidget,
        PageTitle,
        BlogWidget,
        AccountCreate,
        FooterWidget,
    }
}
</script>