<template>
    <div class="account-create-area">
        <div class="container">
            <div class="account-create-content">
                <h2>Apply for an account in minutes</h2>
                <p>Get your AbSwipe account today!</p>
                <router-link to="/contact" class="btn btn-primary">
                    Get Your Account
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountCreate'
}
</script>