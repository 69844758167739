import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/styles/new.css";
import '@/assets/styles/comingsoon.css';
import "./assets/styles/fontawesome.css";
import "@/assets/scripts/app.js";
import VueCountdown from '@chenfengyuan/vue-countdown';
import { VueFire, VueFireAuth, getCurrentUser } from 'vuefire'
import { firebaseApp } from "@/assets/scripts/firebaseInit";
import { initApp } from "@/assets/scripts/app";
router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
        const currentUser = await getCurrentUser()
        if (!currentUser) {
            return {
                path: '/dashboard/login',
                query: {
                    redirectTo: to.fullPath,
                },
            }
        }
    }
})

const app = createApp(App);
app.component(VueCountdown.name, VueCountdown);
app.use(BootstrapVue3).use(VueFire, {
    firebaseApp,
    modules: [
        VueFireAuth(),
    ],
}).use(router)
initApp();
app.mount("#app");
