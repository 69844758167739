<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <h2>Are you a studying abroad?</h2>
                        <div class="bar"></div>
                        <p>Are you having an international enabled visa/master card but unable to withdraw from ATM?</p>
                        <p>Have you been experiencing high rate in currency exchange?</p>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/img/study.png" alt="image">


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesWidget'
}
</script>