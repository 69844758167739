<template>
    <div>
        <NavbarWidget />
        <PageTitle />
        <AboutWidget />
        <TeamWidget />
        <AppDownload />
        <FooterWidget />
    </div>
</template>

<script>
import NavbarWidget from '../layout/Navbar'
import PageTitle from '../about-us/PageTitle'
import AboutWidget from '../about-us/About'
import TeamWidget from '../about-us/Team'
import AppDownload from '../common/AppDownload'
import FooterWidget from '../layout/Footer'

export default {
    name: 'AboutUs',
    components: {
        NavbarWidget,
        PageTitle,
        AboutWidget,
        TeamWidget,
        AppDownload,
        FooterWidget,
    }
}
</script>