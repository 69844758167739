<template>
    <div class="ptb-70 text-center">
        <div class="container">
            <img src="../../assets/img/norecord.png" alt="No Data Found">
        </div>
        <h3 class="mt-5">
            {{ message }}
        </h3>
    </div>
</template>

<script>
export default {
    name: 'NoRecordWidget',
    props: {
        message: String
    }
}
</script>