<template>
    <div>
        <section class="countdown-container overlay">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="wow zoomIn text-white animated" data-wow-delay="0.9s"
                            style="visibility: visible; animation-delay: 0.9s; animation-name: zoomIn;">
                            Coming</h1>

                        <h2 class="element text-white">We are currently working on it. Stay tuned</h2>

                        <ul class="countdown">

                        </ul>

                        <h2 class="element text-white mt-5 mb-2">
                            Enter your email/phone number to receive notification on the day of launching
                        </h2>
                        <!-- subscribe form -->
                        <div class="d-flex justify-content-center">
                            <div v-if="isLoading">
                                <div class="lds-dual-ring"></div>
                            </div>
                            <div id="firstAlert" class="alert" v-else></div>
                            <div id="final-msg" class=""></div>
                        </div>
                        <div class="container mt-2 d-flex justify-content-center">
                            <input type="email" name="email" id="email" class="form-control" required
                                data-error="Email address" placeholder="Email" style="border-radius: 20px 0 0 20px;">
                            <input type="text" name="number" id="number" class="form-control" data-error="Phone Number"
                                placeholder="+917011223344">
                            <button type="submit" id="subscribe" class="btn" @click="subscribe()">
                                <i class="fa fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { db } from "@/assets/scripts/firebaseInit";
import { Timestamp } from "@firebase/firestore";
export default {
    name: 'ComingSoon',
    data() {
        return {
            isLoading: false,
            date: Date.now(),
        }
    },
    methods: {
        subscribe: async function () {
            var vm = this;
            const email = document.querySelector('#email');
            const number = document.querySelector('#number');
            const alert = document.querySelector('.alert');
            if (this.isLoading) return;
            if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                alert.classList.remove('alert-success');
                alert.classList.add('alert-danger');
                alert.innerHTML = 'Invalid email entered';
            } else if (!/^\+(\d{2,3})\d{10,10}$/.test(number.value)) {
                alert.classList.remove('alert-success');
                alert.classList.add('alert-danger');
                alert.innerHTML = 'Phone number should include country code';
            } else {
                vm.isLoading = true;
                await db.setDoc(db.doc(db.firestore, 'LaunchingSubs', email.value), {
                    email: email.value,
                    number: number.value,
                    dateCreated: Timestamp.fromDate(new Date())
                }, { merge: true }).then(() => {
                    vm.isLoading = false;
                    document.querySelector('#final-msg').innerHTML = 'Successfully subscribed, thank you';
                    document.querySelector('#final-msg').classList.add('alert', 'alert-success');
                    email.value = '';
                    number.value = '';
                });
            }
        }
    },

    mounted() {
        (() => {
            var countDownDate = new Date("Feb 15, 2023 15:37:25").getTime();

            // Update the count down every 1 second
            var x = setInterval(function () {

                var now = new Date().getTime();
                var distance = countDownDate - now;
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                document.querySelector(".countdown").innerHTML = `<li>
                                    <span class="seconds">${days}</span>
                                    <h3>Days</h3>
                                    </li><li>
                                    <span class="seconds">${hours}</span>
                                    <h3>Hours</h3>
                                    </li><li>
                                    <span class="seconds">${minutes}</span>
                                    <h3>Minutes</h3>
                                    </li><li>
                                    <span class="seconds">${seconds}</span>
                                    <h3>Seconds</h3>
                                    </li>`;

                if (distance < 0) {
                    clearInterval(x);
                    document.querySelector(".countdown").innerHTML = "Lauched";
                }
            }, 1000);
        })()
    },
}
</script>

<style>
#subscribe {
    background: #337ec0;
    border-radius: 0 20px 20px 0;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #337ec0;
    border-color: #61b5ff transparent #61b5ff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>