<template>
    <div class="faq-area ptb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="faq-content">
                        <h2>Frequently Asked Questions</h2>
                        <div class="bar"></div>

                        <div class="faq-image">
                            <img src="../../assets/img/faq.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Is AbSwipe a registered company?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Yes, we are registered company under the Ministry of Cooperate Affairs, India, as
                                        AbSwipe Capital Services Private Limited (CIN: 615357414376FG).</p>
                                    <p>We are also licenced by Reserve Bank of India (RBI) As Authorized Full Fledge
                                        Money Changers (FFMC).</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        How is AbSwipe different from other money changers?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        What fees do you charge?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est nibh felis tortor
                                        viverra pulvinar nibh tincidunt pellentesque dolor. Sem lectus magna metus sit
                                        felis, ipsum, et. Auctor tellus id nunc nibh felis aliquam.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        What do I need to open a AbSwipe account?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Why do you need my BVN?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>We ask you to confirm your BVN so we can be sure no one is pretending to be you.
                                    </p>
                                    <p>When you confirm your BVN, we match your details with the information on the
                                        national BVN database owned by the Central Bank.</p>
                                    <p>We can't use your BVN to access your other bank account(s).</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        What should I do if I need help?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>You can also send an email to <a
                                            href="mailto:contact@abswipe.com">contact@abswipe.com</a> or call 01 633
                                        5832 between 8:00 am and 5:00 pm on weekdays.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        What do I need to open a AbSwipe account?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        Is my information safe with AbSwipe?
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Yes, your information is safe with us. We secure all users information with
                                        high-security encryption.</p>
                                    <p>We have implemented Two-Factor Authentication in AbSwipe App, no other person can
                                        have access to your data except you or with your consent.</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>

            <div class="faq-contact">
                <div class="section-title">
                    <h2>Do You Have Any Questions</h2>
                    <div class="bar"></div>
                    <p>We are always ready to serve you.</p>
                </div>

                <div class="faq-contact-form">
                    <form id="contactForm">
                        <div v-if="isLoading" class="absolute-b-0">
                            <div>
                                <div class="lds-facebook">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <h4 class="mt-4">Sending...</h4>
                            </div>
                        </div>
                        <div v-if="message != ''" class="absolute-b-0">
                            <div>
                                <div style="width: 80%;">
                                    <i v-if="message.includes('success')" class="fa fa-check-circle"
                                        style="font-size: 60px;color:green;"></i>
                                    <i v-else class="fa fa-exclamation-circle" style="font-size: 60px;color:red;"></i>
                                </div>
                                <div style="width: 80%;">
                                    <h4 class="mt-5">{{ message }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required
                                        data-error="Please enter your name" placeholder="Name">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required
                                        data-error="Please enter your email" placeholder="Email">
                                    <div class="help-block email-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required
                                        data-error="Please enter your number" class="form-control" placeholder="Phone">
                                    <div class="help-block number-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                        data-error="Please enter your subject" placeholder="Subject">
                                    <div class="help-block subject-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6"
                                        required data-error="Write your message" placeholder="Your Message"></textarea>
                                    <div class="help-block body-errors"></div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" @click="submitContact()" class="btn btn-primary">
                                    <span>Send Message</span>
                                </button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "./Accordion";
import AccordionItem from "./AccordionItem";
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
    name: 'FaqArea',
    components: {
        Accordion,
        AccordionItem
    },
    data() {
        return {
            isLoading: false,
            message: ''
        }
    },
    methods: {
        async submitContact() {
            const form = document.getElementById('contactForm');
            form.addEventListener('submit', (e) => {
                const name = document.getElementById('name');
                const number = document.getElementById('phone_number');
                const email = document.getElementById('email');
                const subject = document.getElementById('msg_subject');
                const msg = document.getElementById('message');
                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                    const emailError = document.querySelector('.email-errors');
                    emailError.innerHTML = 'Invalid email entered';
                } else if (!/^\+(\d{2,3})\d{10,10}$/.test(number.value)) {
                    const numberError = document.querySelector('.number-errors');
                    numberError.innerHTML = 'Phone number should contain country code';
                } else if (!/(\w+[.,\n]?)+$/.test(msg.value)) {
                    const bodyError = document.querySelector('.body-errors');
                    bodyError.innerHTML = 'Invalid: message should only contain letter and numbers';
                } else {
                    this.isLoading = true;
                    const functions = getFunctions();
                    const sendContact = httpsCallable(functions, 'contactABS');
                    sendContact({
                        from: email.value,
                        subject: subject.value,
                        name: name.value,
                        number: number.value,
                        body: msg.value
                    })
                        .then(() => {
                            this.isLoading = false;
                            this.message = 'Thank, your message has been successefully received, we\'ll respond to you shortly';
                            setInterval(() => {
                                this.message = '';
                            }, 3000);
                        }).catch(() => {
                            this.isLoading = false;
                            this.message = 'Something went wrong while sending your meassage, please email us from your device mail.'
                            setInterval(() => {
                                this.message = '';
                            }, 3000);
                        });
                }
                form.reset();
                e.preventDefault();
            })
        }
    },
}
</script>

<style>
.absolute-b-0 {
    position: absolute;
    height: 360px;
    width: 100%;
    bottom: 1;
    left: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
</style>