<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">

            <div class="overview-box">

                <div class="overview-content">
                    <div class="content">
                        <h2>Your Data is 100% Secured</h2>
                        <div class="bar"></div>


                        <p><span><i class="flaticon-check-mark"></i> Security is our #1 priority</span></p>
                        <p><span><i class="flaticon-check-mark"></i> Your data is always protected</span>
                        </p>
                        <p><span><i class="flaticon-check-mark"></i> We guard your data as we guard our
                                souls</span>
                        </p>

                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/img/secured.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/img/circle.png" alt="image">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesFour'
}
</script>