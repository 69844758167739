<template>
    <div class="ctp-app-area">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-content">
                        <p>What are you waiting for?</p>
                        <span class="sub">Download App</span>
                        <h3>Let’s get your free copy from apple and play store</h3>
                        <div class="btn-box">
                            <a href="#" @click="show()" class="app-store-btn">
                                <i class="flaticon-apple"></i>
                                Download on
                                <span>App Store</span>
                            </a>

                            <a href="#" @mouseenter="show()" class="play-store-btn">
                                <i class="flaticon-play-store"></i>
                                Download on
                                <span>Google play</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-image">
                        <img src="../../assets/img/app.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="wow bounceIn text-white animated absolute" :style="'display:' + showOrHide" data-wow-delay="0.9s">
            <button @click="hide()" class="close"><i class="fa fa-times"></i></button>

            <ComingSoon />

        </div>
    </div>
</template>

<script>
import ComingSoon from "../coming-soon/ComingSoon";

export default {
    name: 'AppDownload',
    components: {
        ComingSoon
    },
    data() {
        return {
            isCounterVisible: 'none'
        }
    },
    computed: {
        showOrHide() {
            return this.isCounterVisible;
        }
    },
    methods: {
        hide() {
            this.isCounterVisible = 'none';
        },
        show() {
            this.isCounterVisible = 'block';
        },
        voidFunc() { }
    },
}
</script>

<style>

</style>
<!-- <template>
    <div class="app-download-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-image">
                        <div class="main-image">
                            <img src="../../assets/img/mobile-app1.png" alt="image">
                            <img src="../../assets/img/mobile-app2.png" alt="image">
                        </div>

                        <div class="main-mobile-image">
                            <img src="../../assets/img/main-mobile.png" alt="image">
                        </div>

                        <div class="circle-img">
                            <img src="../../assets/img/circle.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="app-download-content">
                        <h2>You can find all the thing you need to </h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip.</p>

                        <div class="btn-box">
                            <a href="https://www.apple.com/app-store/" target="_blank" class="app-store-btn">
                                <i class="flaticon-apple"></i>
                                Download on
                                <span>App Store</span>
                            </a>

                            <a href="https://play.google.com/store/apps" target="_blank" class="play-store-btn">
                                <i class="flaticon-play-store"></i>
                                Download on
                                <span>Google play</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload'
}
</script> -->