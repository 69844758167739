<template>
    <div class="login-area">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-image">
                    <img src="../../assets/img/app-dev.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form">
                                <div class="logo">
                                    <router-link to="/home">
                                        <img src="../../assets/img/h/lockup_abswipe_horizontal.png" width="140"
                                            alt="image">
                                    </router-link>
                                </div>

                                <h3>Way In...</h3>

                                <form>
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" placeholder="Your email address"
                                            class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" id="password" placeholder="Your password"
                                            class="form-control">
                                    </div>

                                    <button type="submit" class="btn btn-primary">Login</button>

                                    <!-- <div class="forgot-password">
                                        <router-link to="/register">
                                            Forgot Password?
                                        </router-link>
                                    </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentUser } from "vuefire";

export default {
    name: 'LoginArea',
    methods: {
    },
    mounted() {
        (async () => {
            const currentUser = await getCurrentUser()
            if (currentUser) {
                const to =
                    this.$route.query.redirectTo && typeof this.$route.query.redirectTo === 'string'
                        ? this.$route.query.redirectTo
                        : '/'

                this.$router.push(to)
            }
        })()
    },
}
</script>