<template>
    <div class="feedback-area ptb-70 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>What customers say about Us</h2>
                <div class="bar"></div>
            </div>

            <div class="feedback-slides">
                <carousel :autoplay="5000" :settings="settings" :wrap-around="changer">

                    <slide v-for="slide in feedbacks" :key="slide.id">
                        <div class="single-feedback">
                            <div class="client-img">
                                <img :src="slide.photoUrl" height="95" alt="image">
                            </div>
                            <h3>{{ slide.name }}</h3>
                            <span>{{ slide.profession }}</span>
                            <p>{{ slide.note }}</p>
                        </div>
                    </slide>


                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { db } from "@/assets/scripts/firebaseInit";
import { useCollection } from 'vuefire'
import { collection } from 'firebase/firestore'

const feedbacks = useCollection(collection(db.firestore, 'Company/Info/Testimonials'))

export default defineComponent({
    name: 'ManagePortfolio',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            feedbacks: feedbacks,
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            changer: false,
        }
    },
    methods: {

    },
    mounted() {
        setTimeout(() => {
            this.changer = !this.changer;
        }, 5000);
    },
})
</script>