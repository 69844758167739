<template>
    <div>
        <div class="about-area ptb-70">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <span>How we were founded</span>
                            <h2>{{ companyInfo.companyName }}</h2>
                            <p v-for="index in aboutCompParag" :key="index">{{ index }}</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="../../assets/img/about-img1.jpg" alt="image">

                            <div class="video-btn popup-youtube" v-on:click="isPopupMethod(isPopup)"
                                style="cursor: pointer">
                                <i class='fas fa-play'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-video" v-if="isPopup">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe v-bind:src="companyInfo.videoUrl"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { companyInfo } from "@/assets/scripts/app.js";

export default {
    name: 'AboutWidget',
    data() {
        return {
            isPopup: false,
            companyInfo: {},
        }
    },
    methods: {
        async fetchTeams() {
            this.companyInfo = companyInfo;
        },
        isPopupMethod(isPopup) {
            return this.isPopup = !isPopup;
        },
    },
    computed: {
        aboutCompParag() {
            const paragArray = String(this.companyInfo.companyDesc).split('[p]');
            return paragArray;
        }
    },
    mounted() {
        this.fetchTeams();
    },
}
</script>